import { Doctor } from "types/doctor";

export const doctorFullName = (
  doctor: Doctor,
  namechangeVEH?: boolean
): string =>
  doctor.firstName &&
  doctor.firstName.toLowerCase().includes("open network") &&
  namechangeVEH
    ? "Doctor of Optometry"
    : doctor.firstName &&
      doctor.firstName.toLowerCase().includes("open network")
    ? "Doctor of Optometry"
    : `Dr. ${doctor.firstName} ${doctor.lastName}`;

export const doctorFullNameVEH = (
  doctor: Doctor,
  namechangeVEH: boolean
): string =>
  doctor.firstName && doctor.firstName.toLowerCase().includes("open network")
    ? namechangeVEH
      ? "Doctor of Optometry"
      : "Doctor of Optometry"
    : `Dr. ${doctor.firstName} ${doctor.lastName}`;

declare let discountVEHDoctor: string;

export const doctorDiscountNameVEH = (): string => {
  discountVEHDoctor =
    typeof discountVEHDoctor !== "undefined" ? discountVEHDoctor : "";
  return discountVEHDoctor;
};
