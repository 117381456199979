import React from "react";
import styles from "./DiscountCard.module.scss";

export const DiscountCard: React.FC<any> = () => {
  return (
    <>
      <div className={styles.discountText}>$25 OFF Purchase, Book Now!</div>
    </>
  );
};
