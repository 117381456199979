import React from "react";
import { useSelector } from "react-redux";
import styles from "./ConnectedDoctorSlots.module.scss";
import { DoctorTimeSlotV2 } from "./DoctorTimeSlotV2";
import { DoctorButton } from "./DoctorButton";
import { AvailabilityForDoctorMobile } from "./AvailabilityForDoctorMobile";
import { Slot } from "types/slot";
import { RootState } from "app/store";
import { selectNextAvailableDateForDoctor } from "features/scheduling/availabilitySummarySlice";
import { selectUpcomingTimeslotsForDoctorByDate } from "features/scheduling/timeSlotsSlice";
import {
  DoctorSlots,
  SelectSlotHandler,
} from "features/scheduling/components/DoctorSlots";
import { Doctor } from "types/doctor";
import { ExamType } from "types/examType";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { Office } from "types/office";

export interface ConnectedDoctorSlotsProps {
  doctor: Doctor;
  officeId: string;
  forDate: string;
  examType: ExamType;
  patientReturnStatus: PatientReturnStatus;
  onSelectSlot?: SelectSlotHandler;
  selectedSlot?: undefined | Slot;
  office: Office;
  version?: number;
  onSelection?: (type: string, value: Slot) => void;
  onSelectNextAvail?: (date: string) => void;
  onNextMobile?: () => void;
}

export const ConnectedDoctorSlots: React.FC<ConnectedDoctorSlotsProps> = ({
  doctor,
  officeId,
  forDate,
  office,
  version,
  selectedSlot,
  onSelection,
  onSelectNextAvail,
  onNextMobile,
  ...otherProps
}) => {
  const slots = useSelector((state: RootState) =>
    selectUpcomingTimeslotsForDoctorByDate(state, doctor.id)
  );
  const availability = useSelector((state: RootState) =>
    selectNextAvailableDateForDoctor(
      state,
      officeId,
      doctor.id,
      forDate,
      office.timeSlotWeeks
    )
  );

  if (version === 2) {
    return (
      <div className={styles.slotWrapper}>
        <DoctorButton
          key={`doctor_${doctor.id}`}
          doctor={doctor}
          //onClick={() => onSelect(doctor)}
          loaded={availability !== "loading"}
          isMobileView={true}
          selectedOffice={office}
          searchDate={forDate}
          availability={availability}
          availabilityInfo={
            <AvailabilityForDoctorMobile
              doctor={doctor}
              office={office}
              forDate={forDate}
              slots={slots}
              availability={availability}
              isMobile={true}
            />
          }
        />
        <DoctorTimeSlotV2
          searchDate={forDate}
          doctorId={doctor.id}
          availability={availability}
          selectedOffice={office}
          onSelection={onSelection}
          isMobileView={true}
          selectedSlot={selectedSlot}
          onSelectNextAvail={onSelectNextAvail}
          onNextMobile={onNextMobile}
        />
      </div>
    );
  }
  return (
    <DoctorSlots
      slots={slots}
      availability={availability}
      doctor={doctor}
      officeId={officeId}
      office={office}
      forDate={forDate}
      {...otherProps}
    />
  );
};
