import React, { useState } from "react";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
// eslint-disable-next-line no-restricted-imports
import { Footer } from "../components/Footer/FooterV2";
import styles from "./AppointmentSuccessV2.module.scss";
import { RootState } from "app/store";
import {
  reinitialize,
  selectCalendarLinks,
  selectCreatedAppointment,
  selectSelectedAppointment,
} from "features/scheduling/appointmentCreationSlice";
import { selectInsuranceData } from "features/insuranceDataSlice";
import successCircle from "images/success-circle.svg";
import { usePageViewTracking } from "hooks/usePageViewTracking";
import successTick from "images/success-tick.svg";
import saveDisk from "images/save-disk.svg";
import profileDefault from "images/profile-default.svg";
import officePlaceholder from "images/office-placeholder.svg";
import officeWhitePlaceholder from "images/office-white-placeholder.svg";
import { CheckInCardNew } from "features/scheduling/components/CheckInCardNew";
import { selectAppointmentData } from "features/appointmentDataSlice";
import { CalendarLinkModal } from "components/CalendarLinkModal";
import * as paths from "features/routing/paths";
import { LayoutV2 } from "features/layout";
import { FooterPortal } from "components/Footer/Footer";
import { DoctorAvatar } from "components/DoctorAvatar";
import { API_DATE_FORMAT } from "api/constants";
import { doctorFullName, doctorDiscountNameVEH } from "utils/doctorFullName";
import { parseStartTime } from "features/appointmentTime";
import { Button, ButtonRole } from "components/Button";
import { maskPhone } from "components/MaskedTextInput/maskPhone";
import { ConfirmAboutYou1 } from "features/checkIn/ConfirmAboutYou1";
import { AboutYou1FormData, InsuranceFormData } from "features/checkIn/types";
import { ConfirmInsurance } from "features/checkIn/ConfirmInsurance";
import { createAboutYouNewRequest } from "api/createAboutYouRequest";
import { createInsuranceCoverageNewRequest } from "api/createInsuranceCoverageRequest";
import { skipInsuranceCoverageNewRequest } from "api/skipInsuranceCoverageRequest";
import { RetiringLabel } from "features/scheduling/components/RetiringLabel";

interface AppointmentReviewPathParams {
  officeId: string;
}

export const AppointmentSuccessNewV2: React.FC = () => {
  const appointmentData = useSelector((state: RootState) =>
    selectAppointmentData(state)
  );
  const selectedAppointment = useSelector(selectSelectedAppointment);

  const tempappointmentData = appointmentData;
  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSubmiting, setSubmiting] = useState(false);
  const [executeOnce, setExecuteOnce] = useState(false);
  const [insChecked, setInsChecked] = useState(false);
  const [addressDATA, setAddressDATA] = useState<AboutYou1FormData>();
  const insuranceFormDataArr = useSelector(selectInsuranceData);
  const createdAppointment = useSelector(selectCreatedAppointment);
  const calendarLinks = useSelector(selectCalendarLinks);
  const routeParams = useParams<AppointmentReviewPathParams>();
  let { officeId } = routeParams;
  if (
    tempappointmentData &&
    !tempappointmentData.doctor &&
    !tempappointmentData.timeSlot
  ) {
    return (
      <Redirect
        to={paths.v2appointmentStart(officeId, dayjs().format(API_DATE_FORMAT))}
      />
    );
  }
  officeId = tempappointmentData.office.id;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (!executeOnce && !createdAppointment && !calendarLinks) {
      setExecuteOnce(true);
      history.push(
        paths.v2appointmentStart(officeId, tempappointmentData.lastRequestUrl)
      );
    }
  }, [
    calendarLinks,
    createdAppointment,
    executeOnce,
    history,
    officeId,
    tempappointmentData.lastRequestUrl,
  ]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    return () => {
      dispatch(reinitialize());
    };
  }, [dispatch]);

  const checkFormInsurance = (insurance: InsuranceFormData) => {
    if (
      insurance.insuranceCarrier &&
      insurance.subscriberFirstName &&
      insurance.subscriberLastName &&
      insurance.subscriberId &&
      insurance.subscriberDateOfBirth &&
      insurance.isPrimaryPolicyHolder &&
      insurance.subscriberSsn &&
      insurance.relationToPatient
    ) {
      if (insurance.insuranceCarrier === "Other Insurance") {
        return insurance.otherInsuranceName ? true : false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const onSubmitNew = async () => {
    setSubmiting(true);
    let isFormValid = false;
    const dummyArr = [];
    if (
      !isEmpty(insuranceFormDataArr[0]) &&
      checkFormInsurance(insuranceFormDataArr[0])
    ) {
      dummyArr.push(insuranceFormDataArr[0]);
    }
    if (
      !isEmpty(insuranceFormDataArr[1]) &&
      checkFormInsurance(insuranceFormDataArr[1])
    ) {
      dummyArr.push(insuranceFormDataArr[1]);
    }
    for (const insurance of insuranceFormDataArr) {
      if (!isEmpty(insurance)) {
        isFormValid = checkFormInsurance(insurance) ? true : false;
        if (!isFormValid) break;
      }
    }
    if (
      addressDATA &&
      addressDATA?.address1 &&
      addressDATA?.city &&
      addressDATA?.state &&
      addressDATA?.zipCode &&
      (isFormValid || insChecked)
    ) {
      try {
        await createAboutYouNewRequest({
          ...addressDATA,
        });
      } catch (err) {
        throw new Error("Unable to save the address");
      }
      if (!insChecked) {
        try {
          await createInsuranceCoverageNewRequest(dummyArr);
        } catch (err) {
          throw new Error("Unable to save the insurance");
        }
        setSubmiting(false);
        history.push(paths.checkInHome());
      } else {
        if (insChecked) {
          try {
            await skipInsuranceCoverageNewRequest();
          } catch (err) {
            throw new Error("Unable to save the skip insurance");
          }
          setSubmiting(false);
          history.push(paths.checkInHome());
        }
        setSubmiting(false);
      }
    } else {
      setTimeout(() => {
        setSubmiting(false);
      }, 2000);
    }
  };

  const getAddressValues = (addressData: any) => {
    setAddressDATA(addressData);
  };

  const handleChangeIns = () => {
    setInsChecked(!insChecked);
  };

  const isFormValidIns = (data: any) => {
    //{formValid:formValid,getInsValues:getValues()}
    if (!data.formValid) {
      setSubmiting(false);
    } else {
      setSubmiting(true);
    }
  };

  const getInsuranceValues = async (insuranceDATA: any) => {
    setSubmiting(false);
  };

  const hyphenPlace = isMobile ? "- " : " - ";
  const vehThreeheadshot =
    tempappointmentData.doctor.offersVEH &&
    tempappointmentData.doctor.headshotUrl
      ? tempappointmentData.doctor.headshotUrl.includes("OD_Group_Final_3.png")
      : false;
  const formattedTime = parseStartTime(tempappointmentData?.timeSlot).format(
    "h:mm A"
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  usePageViewTracking("step-8-appointment-created", {
    doctorId: tempappointmentData?.doctor?.id,
    patientId: createdAppointment?.patientId,
    patientPhoneNumber: tempappointmentData?.patientDetails?.phoneNumber,
    patientSmsOptIn: tempappointmentData?.patientDetails?.smsOptInNotices,
    appointmentId: createdAppointment?.id,
  });
  const showDiscount =
    dayjs().add(13, "day") > dayjs(tempappointmentData.timeSlot.date);
  const discountVEHDoctor = doctorDiscountNameVEH();
  const doctorName =
    tempappointmentData.doctor.offersVEH &&
    tempappointmentData.office.discountVEH &&
    discountVEHDoctor &&
    showDiscount
      ? doctorDiscountNameVEH()
      : doctorFullName(tempappointmentData.doctor);
  return (
    <>
      <LayoutV2 office={tempappointmentData.office}>
        <div className={styles.containerNew}>
          <div className={styles.header}>
            <div className={styles.headerImage}>
              <img
                src={successCircle}
                className={styles.imageCircle}
                alt="circle"
              />
              <img
                src={successTick}
                className={styles.imageTick}
                alt="circle"
              />
            </div>
            <span className={styles.headerText}>YOU'RE SCHEDULED!</span>
            <div className={styles.timeSlotMobile}>
              {dayjs(tempappointmentData.timeSlot.date).format(
                "ddd, MMM D, YYYY"
              )}{" "}
              at {formattedTime}
            </div>
          </div>
          <div className={styles.borderDivider}></div>
          <div className={styles.content}>
            <div>
              <div className={cx(styles.contentBoxV2, styles.doctorPadding)}>
                <div className={styles.contentLeftV2}>
                  <DoctorAvatar
                    doctor={tempappointmentData.doctor}
                    size="small"
                    className={
                      vehThreeheadshot ? styles.avatar3Heads : styles.avatar
                    }
                    callFrom={"successPage"}
                    selectedOffice={tempappointmentData.office}
                  />
                </div>
                <div className={styles.contentRightV2}>
                  <div className={styles.doctorName}>{doctorName}</div>
                  {tempappointmentData.doctor.retiringDate && (
                    <RetiringLabel
                      doctor={tempappointmentData.doctor}
                      calledFrom="successNew"
                    />
                  )}
                  <div className={styles.timeSlot}>
                    {dayjs(tempappointmentData.timeSlot.date).format(
                      "ddd, MMM D, YYYY"
                    )}{" "}
                    at {formattedTime}
                  </div>
                </div>
              </div>
              <div className={styles.borderDivider}></div>
              <div className={cx(styles.contentBoxV2, styles.doctorPadding)}>
                <div className={styles.contentLeftV2}>
                  <img
                    src={isMobile ? officeWhitePlaceholder : officePlaceholder}
                    className={styles.officeImg}
                    alt="Office"
                  />
                </div>
                <div className={styles.contentRightV2}>
                  <div className={styles.officeName}>
                    {tempappointmentData.office.displayName}
                  </div>
                  <div className={styles.officeAddress}>
                    {tempappointmentData.office.address1}, &nbsp;
                    {tempappointmentData.office.city},{" "}
                    {tempappointmentData.office.stateAbbr}&nbsp;
                    {tempappointmentData.office.formattedZipCode} <br />
                    {tempappointmentData.office.formattedPhone}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.borderDivider}></div>
            <div>
              <div className={cx(styles.contentBoxV2, styles.patientMobile)}>
                <div
                  className={cx(styles.contentLeftV2, styles.patientProfileV2)}
                >
                  <img src={profileDefault} alt="circle" />
                </div>
                <div
                  className={cx(styles.contentRightV2, styles.patientDetails)}
                >
                  <div className={styles.patientName}>
                    {tempappointmentData.patientDetails.firstName}{" "}
                    {tempappointmentData.patientDetails.lastName}
                  </div>
                  <div className={styles.patientInfo}>
                    Date of Birth{hyphenPlace}
                    {dayjs(
                      tempappointmentData.patientDetails.dateOfBirth
                    ).format("MM/DD/YYYY")}
                  </div>
                  <div className={styles.patientInfo}>
                    Phone{hyphenPlace}
                    {maskPhone(tempappointmentData.patientDetails.phoneNumber)}
                  </div>
                  <div className={styles.patientInfo}>
                    Email
                    {hyphenPlace + tempappointmentData.patientDetails.email}
                  </div>
                </div>
              </div>
              <div className={styles.borderDivider}></div>
            </div>
            <div className={styles.buttonContainerV2}>
              <Button
                text="Add to Calendar"
                onClick={(ev) => {
                  setModalOpen(true);
                  ev.stopPropagation();
                }}
                role={ButtonRole.Ghost}
                size="large"
              />
            </div>
            <div className={styles.formContainer}>
              <ConfirmAboutYou1
                aboutYou1Data={null}
                getAddressValues={getAddressValues}
                isSubmit={isSubmiting}
                version={2}
              />
              <div className={styles.insCheck}>
                <input
                  type="checkbox"
                  checked={insChecked}
                  onChange={handleChangeIns}
                />
                <label> I WILL NOT BE USING INSURANCE</label>
              </div>
              {!insChecked && (
                <ConfirmInsurance
                  defaultValues={null}
                  isSubmit={isSubmiting}
                  isFormValidIns={isFormValidIns}
                  getInsuranceValues={getInsuranceValues}
                  currentPatient={tempappointmentData.patientDetails}
                  version={2}
                />
              )}
              <CheckInCardNew
                appointment={selectedAppointment}
                onClick={onSubmitNew}
                isSubmit={isSubmiting}
                version={2}
              />
            </div>
          </div>
        </div>

        <div className={styles.line}></div>
        <div className={styles.footer}>
          <div className={styles.wantAnother}>
            <a
              href={
                window.location.origin +
                "/appointments/" +
                tempappointmentData.office.id +
                "/start"
              }
            >
              Need to schedule another exam?
            </a>
          </div>
          <div className={styles.confirmContainer}>
            <img src={saveDisk} className={styles.saveDisk} alt="saveDisk" />A
            confirmation email has been sent to you with a link to your{" "}
            <b>Patient Portal</b>.<br />
            Manage your appointment and check in online through your Patient
            Portal.
          </div>
        </div>
        <CalendarLinkModal
          onRequestClose={() => setModalOpen(false)}
          isModalOpen={isModalOpen}
          links={calendarLinks}
        />
        <Footer formattedPhone={tempappointmentData.office.formattedPhone} />
        <FooterPortal />
      </LayoutV2>
    </>
  );
};
